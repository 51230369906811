import React from 'react';
import { Layout } from '../components/Layout';

export default () => {
  return (
    <Layout>
      I just started up this site so I haven't had a chance to upload projects yet. Check back soon for some cool stuff.
    </Layout>
  );
}
